<template>
  <div class="IPO">
    <!--  导航-->
    <div class="dh">
      <div class="navigation">
        <div class="ico" @click="go()">
          <img class="icon" src="../../assets/img15.png">
          <p class="designation">华钛信息</p>
        </div>
        <div class="selectivity">
          <div class="alternative">
            <div class="xzx1 xzxz1">
              <span class="xzxz" @click="hang()">行业方案<span class="xzl"></span></span>
              <div class="nav1 nav">
                <div class="navxx">
                  <span>行业方案</span>
                  <span>华钛信息致力于帮助各类型企业搭建贴合业务的数据平台目前服务的客户涵盖金融、互联网、制造业、零售业、汽车等多个行业。</span>
                  <span @click="hang()">前往行业方案 <span class="r-jt1"></span></span>
                </div>
                <div class="lin-xx">
                  <div class="hz" v-for="(item, index) in hzData" :key="index">
                    <span class="lin-cut"></span>
                    <p @click="din(item)">{{item.hzz}}</p>
                  </div>
                </div>
                <div class="pt">
                  <img src="../../assets/img188.png">
                </div>
              </div>
            </div>
            <div class="xzx2 xzxz1">
              <span class="xzxz" @click="zi()">自主产品 <span class="xzl"></span></span>
              <div class="nav2 nav">
                <div class="navxx">
                  <span>自主产品</span>
                  <span>华钛信息供应商采购平台以为企业降本增效、提高经营效率、提升数据价值为导向，含供应商管理、寻源管理、采购协同、财务协同等模块。</span>
                  <span @click="zi()">前往自主产品 <span class="r-jt1"></span></span>
                </div>
                <div class="lin-xx">
                  <div class="hz" v-for="(item, index) in hzDatas" :key="index">
                    <span class="lin-cut"></span>
                    <p @click="din(item)">{{item.hzz}}</p>
                  </div>
                </div>
                <div class="pt">
                  <img src="../../assets/img189.png">
                </div>
              </div>
            </div>
            <div class="xzx3 xzxz1">
              <span class="xzxz" @click="fu()">服务范围 <span class="xzl"></span></span>
              <div class="nav3 nav">
                <div class="navxx">
                  <span>服务范围</span>
                  <span>华钛信息以合作共赢的服务理念为核心，为企业提供优质化服务。</span>
                  <span @click="fu()">前往服务范围 <span class="r-jt1"></span></span>
                </div>
                <div class="lin-xx">
                  <div class="hz" v-for="(item, index) in hzDataes" :key="index">
                    <span class="lin-cut"></span>
                    <p @click="din(item)">{{item.hzz}}</p>
                  </div>
                </div>
                <div class="pt">
                  <img src="../../assets/img190.png">
                </div>
              </div>
            </div>
            <div class="xzx4 xzxz1" v-for="(item ,index) in newData" :key="index">
              <span class="xzxz" @click="din(item)">{{item.cname}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--第一层-->
    <div class="d-first">
      <img src="../../assets/img182.png">
      <div class="liang">
        <span class="liang1">某杭州上市制造业</span>
        <span class="liang2">所属类型：企业数字化平台</span>
        <span class="liang3">华钛信息经营数字化平台帮助企业完成了整个成本分析系统的重新架构，通过新技术、新方案，客户的整个系统性能得到了质的飞跃。</span>
      </div>
    </div>
    <!--第二层-->
    <div class="d-second">
      <div class="spreadhead">
        <div class="subhead">
          <div class="moit">
            <img class="img3" src="../../assets/img3.png">
            <span>方案概述</span>
            <img class="img4" src="../../assets/img4.png">
          </div>
        </div>
      </div>
      <span class="infoBox1">
      <span class="paragraph1"
      >随着客户的业务量不断增大，客户的原有的成本分析平台已无法满足大量的成本分析需求，系统运行时间长，获取难度大，数据不准、追朔性差等问题已成为客户始终的难点，在此基础上，华钛信息到客户现场进行客户访谈，平台验证程序演示等，通过1年时间，完成了全新成本分析平台建设，最终将成果移交客户，帮助客户实现了系统的性能飞跃。
<br
/></span>
    </span>
      <div class="spreadhead">
        <div class="subhead">
          <div class="moit">
            <img class="img3" src="../../assets/img3.png">
            <span>功能描述</span>
            <img class="img4" src="../../assets/img4.png">
          </div>
        </div>
      </div>
      <div class="admg">
        <img src="../../assets/img185.png">
      </div>
      <div class="spreadhead">
        <div class="subhead">
          <div class="moit">
            <img class="img3" src="../../assets/img3.png">
            <span>我们的优势</span>
            <img class="img4" src="../../assets/img4.png">
          </div>
        </div>
      </div>
      <span class="paragraph1">
        <span class="paragraph2">
            一、数据量
            <br />
          </span>
          <span class="paragraph3">
            实施前：分摊后约5000万数据量
            <br />
            实施后：分摊后约20亿的数据量
            <br />
            <br />
          </span>
          <span class="paragraph2">
            二、性能
            <br />
          </span>
          <span class="paragraph5">
            实施前：约8小时
            <br />
            实施后：约30分钟
            <br />
            <br />
          </span>
        <span class="paragraph2">
            三.扩展性
            <br />
          </span>
          <span class="paragraph5">
            实施前：几乎无法扩展，需求处于停滞状态
            <br />
            实施后：通过配置可实施需求
            <br />
            <br />
          </span>
          <span class="paragraph2">
            四、追溯性
            <br />
          </span>
          <span class="paragraph5">
            实施前：无法追溯
            <br />
            实施后：所有计算结果均可追溯
            <br />
          </span>
        <span class="paragraph2">
            五、数据明细度
            <br />
          </span>
          <span class="paragraph5">
            实施前：(1)费用分摊仅到品牌维度（2）成本计算只有总成本
            <br />
            实施后：（1）费用分摊到七级架构+区域+客户（2）成本计算到50+个明细科目且可随时扩展
            <br />
          </span>
      </span>
    </div>
    <!--  第三层-->
    <div class="d-thirdy">
      <span class="lx">定制化的解决方案，满足您的实际业务需求</span>
      <span class="lx2">联系我们</span>
    </div>
    <!--    底层-->
    <div class="ground-floor">
      <div class="fbox">
        <div class="fbox1">
          <div class="fbox2">
            <div class="lianxi">
              <div class="fico">
                <img class="ficon" src="../../assets/img2.png">
                <p class="fdesignation">华钛信息</p>
              </div>
              <div class="contway">
                <div class="special">
                  <span>联系电话：</span>
                  <span>021-55962658</span>
                </div>
                <div class="special">
                  <span>商务合作：</span>
                  <span>business@huataish.com</span>
                </div>
              </div>
            </div>
            <div class="cut-line"></div>
            <div class="explain">
              <div class="explain1">
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename">行业方案</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDatas" :key="index">
                        <span @click="din(item)">{{ item.lei }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename">自主产品</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDatass" :key="index">
                        <span @click="din(item)">{{ item.lei1 }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename">服务范围</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDatasss" :key="index">
                        <span @click="din(item)">{{ item.lei2 }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename" @click="cl()">客户案例</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDataes" :key="index">
                        <span>{{ item.lei3 }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename" @click="hz()">合作伙伴</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDataes" :key="index">
                        <span>{{ item.lei3 }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename" @click="xx()">华钛信息</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDataess" :key="index">
                        <span @click="din(item)">{{ item.lei4 }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cut-line1"></div>
          <div class="safety">
            <span>上海总部: 上海市长宁区凯旋路1058号1-2号楼2楼</span>
            <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备20008244号-1</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IPO",
  data() {
    return {
      cur: 0,
      hzData:[
        {
          hzz:"金融行业",
          url:'/FinAnce'
        },
        {
          hzz:"制造行业",
          url:'/Make'
        },
        {
          hzz:"零售行业",
          url:'/ReTail'
        },
        {
          hzz:"汽车行业",
          url:'/AutoMobile'
        },
      ],
      hzDatas:[
        {
          hzz:"供应商管理系统",
          url:'/IndepenDent'
        },
        {
          hzz:"企业数字化平台",
          url:'/IndepenDent'
        },
      ],
      hzDataes:[
        {
          hzz:"定制化开发服务",
          url:'/CustoMization'
        },
        {
          hzz:"产品化解决方案服务",
          url:'/Product'
        },
        {
          hzz:"平台研发",
          url:'/PlatForm'
        },
        {
          hzz:"咨询服务",
          url:'/DigiTization'
        },
        {
          hzz:"运维服务",
          url:'/OperAtion'
        },
        {
          hzz:"外包服务",
          url:'/Epiboly'
        },
      ],
      newData: [
        {
          cname: "客户案例",
          url:'/Clien'
        },
        {
          cname: "合作伙伴",
          url:'/Partner'
        },
        {
          cname: "公司信息",
          url:'/information'
        }
      ],
      foolDatas: [
        {
          lei: "金融行业",
          url: '/FinAnce'
        },
        {
          lei: "制造行业",
          url: 'Make'
        },
        {
          lei: "零售行业",
          url: '/ReTail'
        },
        {
          lei: "汽车行业",
          url:'/AutoMobile'
        },
      ],
      foolDatass: [
        {
          lei1: "供应商管理系统",
          url: '/IndepenDent'
        },
        {
          lei1: "企业经营数字化平台",
          url:'/IndepenDent'
        },
      ],
      foolDatasss: [
        {
          lei2: "定制化开发服务",
          url:'/CustoMization'
        },
        {
          lei2: "产品化解决方案服务",
          url:'/Product'
        },
        {
          lei2: "平台研发",
          url:'/PlatForm'
        },
        {
          lei2: "咨询服务",
          url:'/DigiTization'
        },
        {
          lei2: "运营服务",
          url:'/OperAtion'
        },
        {
          lei2: "外包服务",
          url:'/Epiboly'
        },
      ],
      foolDataes: [

      ],
      foolDataess: [

      ],
    }
  },
  methods:{
    din(item){
      console.log(item.url)
      window.open(item.url,'_blank')
    },
    handleCommand(command) {
      window.open(command,'_blank')
    },
    hang(){
      window.open('/Solution','_blank')
    },
    zi(){
      window.open('/IndepenDent','_blank')
    },
    dian1(){
      window.open('/FinAnce','_blank')
    },
    dian2(){
      window.open('/Make','_blank')
    },
    dian3(){
      window.open('/ReTail','_blank')
    },
    dian4(){
      window.open('/AutoMobile','_blank')
    },
    go(){
      window.open('/','_blank')
    },
    fu(){
      window.open('/scope','_blank')
    },
    hz(){
      window.open('/Partner','_blank')
    },
    xx(){
      window.open('/information','_blank')
    },
    cl(){
      window.open('/Clien','_blank')
    }
  }
}
</script>

<style scoped>
.IPO{
  display: flex;
  flex-direction: column;
  margin: 0 auto 0;
  position: relative;
  width: 100%;
}
/*第一层*/
.d-first{
  width: 100%;
  /*min-width: 1920px;*/
  height: 331px;
  overflow: hidden;
  position: relative;
  background: center top no-repeat;
  -webkit-transition: height .6s,background,.6s;
  transition: height .6s,background,.6s;
}
.d-first img{
  position: absolute;
  left: 50%;
  top: 0;
  width: 1920px;
  height: 100%;
  margin-left: -960px;
}
.d-first .liang{
  color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 632px;
  height: 179px;
  margin-top: 74px;
  margin-left: 45%;
}
.d-first .liang .liang1{
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 33px;
}
.d-first .liang .liang2{
  width: 192px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.71);
  line-height: 22px;
}
.d-first .liang .liang3{
  width: 632px;
  height: 72px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.65);
  line-height: 24px;
}
/*标题*/
.spreadhead {
  width: 100%;
  height: 122px;
  /*margin-bottom: 48px;*/
  /*background: red;*/
}

.spreadhead .subhead {
  display: inline-block;
  width: 100%;
  height: 24px;
  /*background: white;*/
  margin-top: 64px;
  margin-bottom: 12px;
}

.spreadhead .subhead .moit {
  display: flex;
  justify-content: space-between;
  width: 272px;
  height: 24px;
  margin: 0 auto;
  /*background: #124BFF;*/
  font-size: 24px;
  line-height: 24px;
}

.spreadhead .subhead .moit img {
  width: 64px;
  height: 18px;
  margin-top: 3px;
}

.spreadhead .subhead .moit span {
  display: block;
  width: 120px;
  height: 24px;
  font-size: 24px;
  text-align: center;
  line-height: 24px;
  margin: 0 auto;
  overflow-wrap: break-word;
}
.spreadhead .details {
  display: block;
  /*display: inline-block;*/
  width: 476px;
  height: 22px;
  font-size: 14px;
  text-align: center;
  line-height: 22px;
  color: #666F8F;
  margin: auto;
}
/*第二层*/
.d-second{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 1527px;
}
.d-second .infoBox1{
  width: 1180px;
  height: 168px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  line-height: 28px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
}
.d-second .paragraph1 {
  width: 1180px;
  height: 631px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  line-height: 28px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
}
.d-second .paragraph2{
  font-weight: 700;
  font-size: 18px;}
.d-second .admg{
  width: 100%;
  height: 434px;
}
.d-second .admg img{
  display: block;
  width: 878px;
  height: 434px;
  margin: 0 auto;
}
/*第三层*/
.d-thirdy{
  display: flex;
  flex-direction: column;
  background-image: url("../../assets/img95.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 286px;
}
.d-thirdy .lx{
  width: 500px;
  font-size: 25px;
  color: black;
  margin: 46px auto;
}
.d-thirdy .lx2{
  width: 226px;
  height: 48px;
  background: #124BFF;
  margin: 25px auto;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  line-height: 48px;
}

</style>
  <style>
  .el-dropdown {
    color: black;
    line-height: 60px;
    font-size: 16px;
    margin-right: 74px;
  }

.el-button.is-round {
  margin-top: 16px;
  width: 88px;
  text-align: center;
  padding: 0px;
  height: 36px;
  /*background-color: rgba(18, 75, 255, 1);*/
}

.el-carousel__item img {
  width: 100%;
  line-height: 200px;
  margin: 0;
}

.el-carousel__arrow--left {
  border: 1px solid #124BFF;
}

.el-carousel__arrow--right {
  border: 1px solid #124BFF;
}

.el-carousel__arrow i {
  color: white;
}
</style>